import { Container, Row, Col } from "react-bootstrap";
import { MailchimpForm } from "./MailchimpForm";
import logo from '../assets/imgLogo/logoBaobab.png';
import navIcon1 from "../assets/SocialIcons/nav-icon1.svg";
import navIcon2 from "../assets/SocialIcons/twitter.svg";
import navIcon3 from "../assets/SocialIcons/tiktok.svg";
import navIcon4 from '../assets/SocialIcons/mail2.svg';
import laGuilde from '../assets/imgLogo/laGuilde.png';
import React, { useState, useContext } from 'react';
import { LanguageContext } from './contextLang';
import traduction from './traduction';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import '../Style.css'; 

export const Footer = () => {
  const handleClickMail = () => {
    window.open('mailto:support@baobabgames.group?subject=Subject&body=Body%20goes%20here');
  };

  const [modalOpen, setModalOpen] = useState(false);
  const { language } = useContext(LanguageContext);
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,

        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
        },
      },
      
    ],
  };

  const companyLogos = [
    { src: laGuilde, alt: "La Guilde" },
    { src: logo, alt: "Logo Baobab" },
  ];
  const getMarginLeft = () => {
    if (window.innerWidth >= 1000) {
      return "100rem"; // Large margin for large screens
    } else if (window.innerWidth >= 568) {
      return "100px"; // Moderate margin for medium screens
    }
    return "0"; // No margin for small screens
  };
  return (
    <footer className="footer">
      <Container>
        {/* Company Logos Slider */}
        
        {/* Mailchimp Form */}
        <Row className="align-items-center">
          <Col xs={12}>
            <MailchimpForm />
          </Col>
        </Row>
        <h1 className="text-center mb-4">{traduction[language].Partenaires}</h1>
        <Slider {...sliderSettings} className="logo-slider">
            {companyLogos.map((logo, index) => (
              <div
                key={index}
                className="logo-item"
                style={{
                  // padding: "0 5px", // Adds spacing
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width:"70%",
                }}
              >
                <img
                  src={logo.src}
                  alt={logo.alt}
                  style={{
                    marginLeft: getMarginLeft(),
                    padding:"-200px",
                    maxWidth: "100%", // Adjust width to fit within the slide
                    height: "auto",
                  }}
                />
              </div>
            ))}
          </Slider>

        
        {/* Footer Content */}
        <Row className="footer-content">
          {/* <Col xs={12} sm={4} className="text-center mb-4">
            <img src={laGuilde} alt="La Guilde" style={{ maxWidth: '150px' }} />
            <h3>{traduction[language].Partenaires}</h3>
          </Col>
          <Col xs={12} sm={4} className="text-center mb-4">
            <img src={logo} alt="Logo" style={{ maxWidth: '150px' }} />
          </Col> */}
          <Col xs={12} sm={4} className="text-center"style={{margin:"2rem"}} >
            <div className="social-icon">
              <a href="https://www.linkedin.com/company/baobabgames/"><img src={navIcon1} alt="Icon" /></a>
              <a href="https://twitter.com/_Baobab_Games"><img src={navIcon2} alt="Icon" /></a>
              <a href="https://www.tiktok.com/@baobab_games?is_from_webapp=1&sender_device=pc"><img src={navIcon3} alt="Icon" /></a>
              <a onClick={handleClickMail}><img src={navIcon4} alt="" /></a>
            </div>
            <p>{traduction[language].copyright}</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
